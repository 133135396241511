<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="99%"
            :center="true"
            top="10px"
            :close-on-click-modal="false"
            :before-close="() => $emit('exitGameRecordEditDialog')"
        >
            <el-descriptions
                direction="vertical"
                :column="3"
                :labelStyle="{'text-align':'center','width':'33%'}"
                :contentStyle="{'text-align': 'center'}"
                :colon="false"
                border
            >
                <el-descriptions-item>
                    <template slot="label">
                        <span>对局类型</span>
                    </template>
                    <div>{{ DetailData.type }}</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>对局人数</span>
                    </template>
                    <div>{{ DetailData.user_num+'人' }}</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>对局时间</span>
                    </template>
                    <div>{{ DetailData.time }}</div>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions
                :column="1"
                :labelStyle="{'text-align':'center','background-color':'#FFFFFF','width':'30px'}"
                :contentStyle="{'text-align': 'center','background-color':'#FFFFFF'}"
                :colon="false"
                border
            >
                <el-descriptions-item>
                    <template slot="label">
                        <div><img :src="$store.state.baseURL+'icon/shengli.png'" height="50px"></div>
                    </template>
                    <el-descriptions
                        direction="vertical"
                        :column="DetailData.detail.win.length"
                        :labelStyle="{'text-align':'center','background-color':'#FFFFFF'}"
                        :contentStyle="{'text-align': 'center','background-color':'#FFFFFF','padding':'0','min-width':'150px'}"
                        :colon="false"
                        border
                    >
                        <el-descriptions-item v-for="item in DetailData.detail.win" :key="item.name">
                            <template slot="label">
                                <img :src="item.picture" height="160px" alt="" style="border-radius: 10px;">
                            </template>
                            <div v-if="item.role_gender === 1">
                                <el-row>
                                    <el-col :span="12" style="text-align: right;font-weight: bold;">角色名称&nbsp;</el-col>
                                    <el-col :span="12" style="text-align: left;">
                                        <img :src="$store.state.baseURL+'icon/xingbienan.png'" height="10px"><span style="color: #2894FF;">{{ item.role }}</span>
                                    </el-col>
                                </el-row>
                            </div>
                            <div v-else-if="item.role_gender === 2">
                                <el-row>
                                    <el-col :span="12" style="text-align: right;font-weight: bold;">角色名称&nbsp;</el-col>
                                    <el-col :span="12" style="text-align: left;">
                                        <img :src="$store.state.baseURL+'icon/xingbienv.png'" height="10px"><span style="color: #FF60AF;">{{ item.role }}</span>
                                    </el-col>
                                </el-row>
                            </div>
                            <el-row>
                                <el-col :span="12" style="text-align: right;font-weight: bold;">玩家昵称&nbsp;</el-col>
                                <el-col :span="12" style="text-align: left;">
                                    <span style="color: #921AFF;">{{ item.name }}</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12" style="text-align: right;font-weight: bold;">玩家阵营&nbsp;</el-col>
                                <el-col :span="12" style="text-align: left;">
                                    <el-tag v-if="item.group === '军情'" effect="plain" size="mini">{{ item.group }}</el-tag>
                                    <el-tag v-else-if="item.group === '潜伏'" type="danger" effect="plain" size="mini">{{ item.group }}</el-tag>
                                    <el-tag v-else type="success" effect="plain" size="mini">{{ item.group }}</el-tag>
                                    <el-tag style="margin-left: 2px;" v-if="(item.is_dead === '死亡') && (item.murder === item.name)" type="danger" effect="dark" size="mini">自杀</el-tag>
                                    <el-tag style="margin-left: 2px;" v-else-if="(item.is_dead === '死亡') && (item.murder !== item.name)" type="info" effect="dark" size="mini">死亡</el-tag>
                                    <el-tag style="margin-left: 2px;" v-else-if="item.is_dead === '败露'" type="warning" effect="dark" size="mini">败露</el-tag>
                                </el-col>
                            </el-row>
                            <el-row v-if="(item.is_dead === '死亡') && (item.murder !== item.name)">
                                <el-col :span="12" style="text-align: right;font-weight: bold;">凶手&nbsp;</el-col>
                                <el-col :span="12" style="text-align: left;">
                                    <span style="color: #FF0000;">{{ item.murder }}</span>
                                </el-col>
                            </el-row>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <div><img :src="$store.state.baseURL+'icon/shibai.png'" height="50px"></div>
                    </template>
                    <el-descriptions
                        direction="vertical"
                        :column="DetailData.detail.lose.length"
                        :labelStyle="{'text-align':'center','background-color':'#FFFFFF'}"
                        :contentStyle="{'text-align': 'center','background-color':'#FFFFFF','padding':'0','min-width':'150px'}"
                        :colon="false"
                        border
                    >
                        <el-descriptions-item v-for="item in DetailData.detail.lose" :key="item.name">
                            <template slot="label">
                                <img :src="item.picture" height="160px" alt="" style="border-radius: 10px;">
                            </template>
                            <div v-if="item.role_gender === 1">
                                <el-row>
                                    <el-col :span="12" style="text-align: right;font-weight: bold;">角色名称&nbsp;</el-col>
                                    <el-col :span="12" style="text-align: left;">
                                        <img :src="$store.state.baseURL+'icon/xingbienan.png'" height="10px"><span style="color: #2894FF;">{{ item.role }}</span>
                                    </el-col>
                                </el-row>
                            </div>
                            <div v-else-if="item.role_gender === 2">
                                <el-row>
                                    <el-col :span="12" style="text-align: right;font-weight: bold;">角色名称&nbsp;</el-col>
                                    <el-col :span="12" style="text-align: left;">
                                        <img :src="$store.state.baseURL+'icon/xingbienv.png'" height="10px"><span style="color: #FF60AF;">{{ item.role }}</span>
                                    </el-col>
                                </el-row>
                            </div>
                            <el-row>
                                <el-col :span="12" style="text-align: right;font-weight: bold;">玩家昵称&nbsp;</el-col>
                                <el-col :span="12" style="text-align: left;">
                                    <span style="color: #921AFF;">{{ item.name }}</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12" style="text-align: right;font-weight: bold;">玩家阵营&nbsp;</el-col>
                                <el-col :span="12" style="text-align: left;">
                                    <el-tag v-if="item.group === '军情'" effect="plain" size="mini">{{ item.group }}</el-tag>
                                    <el-tag v-else-if="item.group === '潜伏'" type="danger" effect="plain" size="mini">{{ item.group }}</el-tag>
                                    <el-tag v-else  type="success" effect="plain" size="mini">{{ item.group }}</el-tag>
                                    <el-tag style="margin-left: 2px;" v-if="(item.is_dead === '死亡') && (item.murder === item.name)" type="danger" effect="dark" size="mini">自杀</el-tag>
                                    <el-tag style="margin-left: 2px;" v-else-if="(item.is_dead === '死亡') && (item.murder !== item.name)" type="info" effect="dark" size="mini">死亡</el-tag>
                                    <el-tag style="margin-left: 2px;" v-else-if="item.is_dead === '败露'" type="warning" effect="dark" size="mini">败露</el-tag>
                                </el-col>
                            </el-row>
                            <el-row v-if="(item.is_dead === '死亡') && (item.murder !== item.name)">
                                <el-col :span="12" style="text-align: right;font-weight: bold;">凶手&nbsp;</el-col>
                                <el-col :span="12" style="text-align: left;">
                                    <span style="color: #FF0000;">{{ item.murder }}</span>
                                </el-col>
                            </el-row>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name:'GameRecordEditComponent',
    data(){
        return {}
    },
    computed:{},
    methods:{},
    filters:{},
    props:{
        dialogFormVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        DetailData: {
            type: Object,
            default() {
                return {
                    detail: {
                        win: [],
                        lose: []
                    }
                }
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
.win {
    font-size: 50px;
    color: #FF0000;
}
.lose {
    font-size: 50px;
    color: #000000;
}
</style>