<template>
    <div>
        <game-record-all-search-bar-component
            :AllMode="AllMode"
            @search="get_game_record_all_index"
        ></game-record-all-search-bar-component>
        <el-card class="box_card" v-loading.fullscreen.lock="loading">
            <el-table
                :data="table_data"
                style="width: 100%"
                border
                stripe
                highlight-current-row
            >
                <el-table-column
                    align="center"
                    type="index"
                    min-width="10px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="type"
                    label="类型"
                    min-width="70px"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.type }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="user_num"
                    label="人数"
                    min-width="50px"
                >
                    <template slot-scope="scope">
                        <span style="color: #2828FF;">{{ scope.row.user_num }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    min-width="275px"
                >
                    <template v-slot:header>
                        <span style="color: #CE0000;font-weight: bold;">胜利</span>
                    </template>
                    <template slot-scope="scope">
                        <div v-for="item in scope.row.detail.win" :key="item.name" style="text-align: left;">
                            <el-tag v-if="item.group === '军情'" effect="plain" size="mini">{{ item.group }}</el-tag>
                            <el-tag v-else-if="item.group === '潜伏'" type="danger" effect="plain" size="mini">{{ item.group }}</el-tag>
                            <el-tag v-else-if="item.group === '酱油'"  type="success" effect="plain" size="mini">{{ item.group }}</el-tag>
                            <span>【<span style="color: #921AFF;">{{ item.name }}</span>】</span>
                            <img v-if="item.role_gender === 1" :src="$store.state.baseURL+'icon/xingbienan.png'" height="10px"><span v-if="item.role_gender === 1" style="color: #2894FF;">{{ item.role }}</span>
                            <img v-if="item.role_gender === 2" :src="$store.state.baseURL+'icon/xingbienv.png'" height="10px"><span v-if="item.role_gender === 2" style="color: #FF60AF;">{{ item.role }}</span>
                            <el-tag style="margin-left: 2px;" v-if="(item.is_dead === '死亡') && (item.murder === item.name)" type="danger" effect="dark" size="mini">自杀</el-tag>
                            <el-tag style="margin-left: 2px;" v-else-if="(item.is_dead === '死亡') && (item.murder !== item.name)" type="info" effect="dark" size="mini">死亡</el-tag>
                            <el-tag style="margin-left: 2px;" v-else-if="item.is_dead === '败露'" type="warning" effect="dark" size="mini">败露</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    min-width="275px"
                >
                    <template v-slot:header>
                        <span style="color: #000000;font-weight: bold;">失败</span>
                    </template>
                    <template slot-scope="scope">
                        <div v-for="item in scope.row.detail.lose" :key="item.name" style="text-align: left;">
                            <el-tag v-if="item.group === '军情'" effect="plain" size="mini">{{ item.group }}</el-tag>
                            <el-tag v-else-if="item.group === '潜伏'" type="danger" effect="plain" size="mini">{{ item.group }}</el-tag>
                            <el-tag v-else-if="item.group === '酱油'"  type="success" effect="plain" size="mini">{{ item.group }}</el-tag>
                            <span>【<span style="color: #921AFF;">{{ item.name }}</span>】</span>
                            <img v-if="item.role_gender === 1" :src="$store.state.baseURL+'icon/xingbienan.png'" height="10px"><span v-if="item.role_gender === 1" style="color: #2894FF;">{{ item.role }}</span>
                            <img v-if="item.role_gender === 2" :src="$store.state.baseURL+'icon/xingbienv.png'" height="10px"><span v-if="item.role_gender === 2" style="color: #FF60AF;">{{ item.role }}</span>
                            <el-tag style="margin-left: 2px;" v-if="(item.is_dead === '死亡') && (item.murder === item.name)" type="danger" effect="dark" size="mini">自杀</el-tag>
                            <el-tag style="margin-left: 2px;" v-else-if="(item.is_dead === '死亡') && (item.murder !== item.name)" type="info" effect="dark" size="mini">死亡</el-tag>
                            <el-tag style="margin-left: 2px;" v-else-if="item.is_dead === '败露'" type="warning" effect="dark" size="mini">败露</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="time"
                    label="对局时间"
                    min-width="100px"
                >
                    <template slot-scope="scope">
                        <div>{{ scope.row.time | format_data }}</div>
                        <div>{{ scope.row.time | format_time }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="查看"
                    min-width="70px"
                >
                    <template slot-scope="scope">
                        <el-button @click="detail(scope.row)" size="mini">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <game-record-edit-component
                :dialogFormVisible="detailDialogVisible"
                :DetailData="detailDialogDate"
                @exitGameRecordEditDialog="exitGameRecordEditDialog"
            ></game-record-edit-component>
        </el-card>
        <el-card class="box-card">
            <el-pagination
                background
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next, jumper"
                :current-page="cond.page"
                :page-size="100"
                :pager-count=7
                :total=total
            >
            </el-pagination>
        </el-card>
    </div>
</template>

<script>
import { GameRecordAllIndexRequest } from '@/network/query/GameRecord.js'

import GameRecordAllSearchBarComponent from '@/components/query/GameRecord/GameRecordAllSearchBarComponent'
import GameRecordEditComponent from '@/components/query/GameRecord/GameRecordEditComponent'

export default {
    name: 'AuthTeamView',
    data() {
        return {
            loading: true,
            cond: {
                name: '',
                mode:'',
                role: '',
                starttime: '',
                endtime: '',
                page: 1
            },
            total: 0,
            table_data: [],
            detailDialogVisible: false,
            detailDialogDate: {
                detail: {
                    win: [],
                    lose: []
                }
            },
            AllMode:[
                { id: 1,name: '身份' },
                { id: 2,name: '3V3' },
                { id: 3,name: '酱油' },
            ]
        }
    },
    computed: {},
    methods: {
        get_game_record_all_index(param = {}) {
            // console.log(param);return
            this.loading = true
            this.cond.mode = param.mode ?? this.cond.mode
            this.cond.role = param.role ?? this.cond.role
            this.cond.name = param.name ?? this.cond.name
            this.cond.starttime = param.starttime ?? this.cond.starttime
            this.cond.endtime = param.endtime ?? this.cond.endtime
            this.cond.page = param.page ?? this.cond.page
            GameRecordAllIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.table_data = s.result.data
                        this.total = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })

                //滚动条回顶部
                this.$nextTick(() => {
                    document.getElementById('el_main').scrollTop = 0;
                })
        },
        detail(row) {
            this.detailDialogVisible = true
            this.detailDialogDate = row
        },
        exitGameRecordEditDialog() {
            this.detailDialogVisible = false
        },
        handleCurrentChange(val) {
            this.cond.page = val
            this.get_game_record_all_index()
        },
    },
    filters: {
        format_data(v) {
            return v.split(' ')[0]
        },
        format_time(v) {
            return v.split(' ')[1]
        }
    },
    props: {},
    created() {
        this.get_game_record_all_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        GameRecordAllSearchBarComponent,
        GameRecordEditComponent
    },
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 3px;
}
</style>