<template>
    <el-card class="box-card" sytle="padding: 5px;">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <div>
                <el-form-item label="包含玩家" prop="name">
                    <el-input
                        v-model="form.name"
                        placeholder="多个玩家用空格隔开，最多支持3个"
                        @keyup.enter.native="search()"
                        clearable
                        class="name_c"
                    ></el-input>
                </el-form-item>
                <el-form-item label="对局时间起">
                    <el-date-picker
                        v-model="form.starttime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="所有时间"
                        class="time_c">
                    </el-date-picker>
                </el-form-item>
            </div>
            <div>
                <el-form-item label="游戏模式">
                    <el-select
                        v-model="form.mode"
                        placeholder="全模式"
                        clearable
                        class="mode_c"
                    >
                        <el-option
                            v-for="item in AllMode"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="包含角色" prop="role">
                    <el-input
                        v-model="form.role"
                        placeholder="角色名"
                        @keyup.enter.native="search()"
                        clearable
                        class="role_c"
                    ></el-input>
                </el-form-item>
                <el-form-item label="对局时间止">
                    <el-date-picker
                        v-model="form.endtime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="所有时间"
                        class="time_c">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="search">搜索</el-button>
                    <el-button type="warning" plain @click="resetbtn()">重置</el-button>
                </el-form-item>
            </div>
        </el-form>
    </el-card>
</template>

<script>
export default {
    name: 'GameRecordMySearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                name: '',
                mode:'',
                role: '',
                starttime: '',
                endtime: '',
                page: 1
            }
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.form = {
                name: '',
                mode:'',
                role: '',
                starttime: '',
                endtime: '',
                page: 1
            }
        }
    },
    filters: {},
    props: {
        AllMode: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 3px;
}

.mode_c {
    width: 150px;
}
.role_c {
    width: 180px;
}
.name_c {
    width: 410px;
}
.time_c {
    width: 150px;
}
</style>